<template>
  <router-view/>
</template>

<style lang="scss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  body {
    background-color: #1D1D1D;
  }
</style>