<template>
    <div class="">
        <div @click="$router.push('/'); store.getStudentData()" class="flex text-[#A1D9F7] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-white">
                Назад
            </div>
        </div>
        <div class="flex flex-col justify-center px-[10px] mt-[40px]">
            <div class="bg-[#3B9BF4] mt-4 rounded-[9px] text-[#1D1D1D] text-center px-3 py-2 w-full text-base font-semibold">Виберіть тариф</div>

            <div class="flex justify-center items-center mt-4">
                <div class="w-full flex justify-center relative">
                    <select  class="outline-none text-[#FFF] placeholder-gray-500 rounded-[9px] py-1.5 px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]">
                        <option v-for="tariff in allTariffs" :value="tariff.id" class="bg-[#252526]">{{ tariff.name }}</option>
                    </select>
                </div>
            </div>

            <button @click="createTransaction" class="bg-[#4EAE3B] mt-4 rounded-[9px] text-white px-3 py-2 w-full text-base font-semibold flex items-center align-middle justify-between">
                <div class="w-full">Сплатити</div>

                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="white"/>
                </svg>

            </button>
        </div>
    </div>
</template>

<script>
    import { onMounted, ref, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import { useMainStore } from '@/mobileApp/store/mainStore'
    import { createWayforpayTransaction, getTariffsByRoleId } from '@/mobileApp/services/apiRequests'

    export default {
        name: 'payment-view',
        
        setup() {
            const store = useMainStore()
            const router = useRouter()
            const amount = ref(null)

            const allTariffs = ref([])

            onMounted(async () => {
                const res = await getTariffsByRoleId(store.student.tariff_role_id)
                allTariffs.value = res.data
            })

            const createTransaction = async () => {
                // const res = await createWayforpayTransaction(
                //     amount.value,
                //     store.student.student_id,
                //     store.student.name,
                //     store.student.surname,
                //     store.student.email,
                //     store.student.phone
                // )
                // console.log('res.data ---------- ', res.data)
                // window.open(res.data.payment.url, '_parent')
                // router.push('/payment-redirect')
                alert('Платіжна система не підключена. Оплачувати наразі неможливо')
            }

            const createDebtTransaction = async () => {
                // const debt = store.studentBalance * -1
                // const res = await createWayforpayTransaction(
                //     debt,
                //     store.student.student_id,
                //     store.student.name,
                //     store.student.surname,
                //     store.student.email,
                //     store.student.phone
                // )
                // console.log('res.data ---------- ', res.data)
                // window.open(res.data.payment.url, '_parent')
                // router.push('/payment-redirect')
                alert('Платіжна система не підключена. Оплачувати наразі неможливо')
            }

            return {
                amount,
                router,
                store,
                createTransaction,
                createDebtTransaction,
                allTariffs
            }
        }
    }
</script>
