<template>
    <div class="fixed overflow-auto w-full h-[102vh] z-[200] bg-[#1D1D1D] pb-[70px] pt-[20px]">
        <div @click="$emit('close')" class="flex items-center ml-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <span class="ml-1 text-[#FFF]">Назад</span>
        </div>
        <div class="flex flex-col justify-center items-center mt-[20px]">
            <div 
                v-for="image in mappedImages"
                @click="image.checked = !image.checked" 
                class="flex justify-center mb-[10px]"
                :key="image.id"   
            >
                <img 
                    :src="image.url" 
                    class="w-[90%]"
                    :class="{
                        'border-[#F9245A] border-[6px]': image.checked && documentType != 'photos_for_passport',
                        'bg-white p-3' : documentType == 'photos_for_passport'
                    }" 
                >
            </div>
            <div v-if="documentType == 'photos_for_passport'" class="text-[#A1D9F7] text-center w-[90%] mx-auto">
                Ви можете змінити фото на паспорт, завантаживши нове з блока документів
            </div>
        </div>
        
        <button v-if="documentType != 'photos_for_passport'" @click="deleteCheckedImages" class="bg-[#F9245A] fixed bottom-4 left-[50%] translate-x-[-50%] rounded-[9px] text-white px-3 py-2 w-[92%] text-base font-semibold flex items-center text-center align-middle justify-between mt-2 mx-auto">
            <span class="mx-auto">Видалити</span>
        </button>
        
    </div>
</template>

<script>
    import { ref, onBeforeMount } from 'vue'
    import { deleteStudentFile } from '@/mobileApp/services/apiRequests'
    import { useMainStore } from '@/mobileApp/store/mainStore'
 
    export default {
        name: 'image-viewer',
        props: {
            images: {
                type: String,
                default: ''
            },
            documentType: {
                type: String,
                default: ''
            }
        },
        setup(props, { emit }) {
            const apiURL = ref(process.env.VUE_APP_API_URL)
            const store = useMainStore()
            const mappedImages = ref([])

            const deleteCheckedImages = async () => {
                const checkedImages = mappedImages.value.filter(image => image.checked)
                for (let image of checkedImages) {
                    const res = await deleteStudentFile(store.student.student_id, props.documentType, image.id)
                }
                store.getStudentData()
                emit('close')
            }

            onBeforeMount(() => {
                console.log('images', props.images)
                mappedImages.value = props.images.map(image => {
                    return {
                        id: image,
                        url: apiURL.value + 'static/' + image,
                        checked: false
                    }
                })
            })

            return {
                apiURL,
                mappedImages,
                deleteCheckedImages
            }
        }
    }
</script>
