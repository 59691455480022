<template>
    <div class="w-[250px] h-[520px] bg-[#1D1D1D] border-[1px] border-[#252526] rounded-[10px]">
        <ImageCropper class="w-[248px] h-[250px]"
            v-if="showImageCropper"
            :image="imageToCrop"
            :width="'250px'"
            :height="'200px'"
            @processedImage="processImage"
        />
        <div>
            <div class="relative">
                <img 
                    @click="uploadPhoto"
                    src="../../assets/upload_icon_light.svg" 
                    class="absolute left-[210px] top-[20px] cursor-pointer z-40"
                >
                <input 
                    ref="fileInput"
                    type="file" 
                    class="hidden"
                    :value="inputValue"
                    @change="loadImage"
                />
            </div>
            <div class="flex justify-center h-[182px] w-full">
                <img :src="photo" @error="photo = require('../../assets/student_photo_placeholder.svg')" class="relative top-[10px] student-logo">
            </div>
        </div>

        <div class="bg-[#4EAE3B] w-full h-[96px] relative top-[10px]">
            <div class="text-[30px] font-semibold text-center">
                <div class="h-[45px]" v-if="store.student.name.length > 0 || store.student.surname.length > 0">
                    <div :class="
                        {
                            'marquee-container' : store.student.name.length + store.student.surname.length + 1 > 13,
                        }
                    ">
                        <div class="surname font-bold" 
                            :class="{
                                'marquee' : store.student.name.length + store.student.surname.length + 1 > 13,
                            }"
                        >
                            <span v-if="store.student.name.length + store.student.surname.length + 1 > 13" v-for="z in 100">{{ store.student.name + ' ' + store.student.surname + '&nbsp;&nbsp;' }}</span>
                            <span v-else>{{ store.student.name + ' ' + store.student.surname }}</span>
                        </div>
                    </div>
                </div>
                <span v-else class="opacity-25">Лєо Мессі</span>
            </div>
            <div class="flex relative justify-between top-[10px] mx-[8px]">
                <div class="flex flex-col items-center justify-center mr-[22px]">
                    <div class="text-[9px]">група</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="store.student.group_name.length > 0" class="truncate">{{ store.student.group_name }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex flex-col items-center justify-center  font-normal mr-[22px]">
                    <div class="text-[9px]">вік</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="store.student.age > 0">{{ store.student.age }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex flex-col items-center justify-center  font-normal mr-[22px]">
                    <div class="text-[9px]">амплуа</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="store.student.amplua.length > 0">{{ store.student.amplua }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex flex-col items-center justify-center  font-normal mr-[22px]">
                    <div class="text-[9px]">номер</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="store.student.player_number > 0">{{ store.student.player_number }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex  items-center justify-center font-normal mr] mt-[5px]">
                    <img src="../../assets/logo.svg" class="w-[20px]">
                    <div class="text-[#1D1D1D] mx-1 mb-[2px]">|</div>
                    <img src="../../assets/uk_flag_dark.svg" class="w-[25px]">
                </div>
            </div>
        </div>

        <div class="mx-[10px] relative top-[40px]">
            <div class="flex justify-between items-center align-middle mb-[3px]">
                <div>
                    <input v-model="store.student.surname" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Прізвище" type="text">
                </div>
                <div class="font-normal text-[#FFFFFF80] opacity-90 text-[11px]">
                    прізвище
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[5px]">
                <div>
                    <input v-model="store.student.name" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Ім'я" type="text">
                </div>
                <div class="font-normal text-[#FFFFFF80] opacity-90 text-[11px]">
                    ім'я
                </div>
            </div>
            <div class="flex justify-between w-full items-center align-middle mb-[3px]">
                <div class="w-full">
                    <input v-model="store.student.patronymic" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="По батькові" type="text">
                </div>
                <div class="font-normal text-[#FFFFFF80] text-end w-full opacity-90 text-[11px]">
                    по батькові
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[3px]">
                <div>
                    <input v-model="formattedBirthDate" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[100%] truncate placeholder:opacity-60" placeholder="12.12.2012" type="date">
                </div>
                <div class="font-normal text-[#FFFFFF80] opacity-90 text-[11px]">
                    дата народження
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[3px]">
                <div>
                    <input v-model="store.student.phone" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="+38000000000" type="text">
                </div>
                <div class="font-normal text-[#FFFFFF80] opacity-90 text-[11px]">
                    телефон
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[5px]">
                <div>
                    <input v-model="store.student.email" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="student@gmail.com" type="text">
                </div>
                <div class="font-normal text-[#FFFFFF80] opacity-90 text-[11px]">
                    email
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input v-model="store.student.nationality" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Українець" type="text">
                </div>
                <div class="font-normal text-[#FFFFFF80] opacity-90 text-[11px]">
                    національність
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { ref, onMounted, watch, computed } from 'vue'
    import { useMainStore } from '../../store/mainStore'
    import ImageCropper from './ImageCropper.vue'
    
    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }

    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }

    export default {
        name: 'new-info-card',

        setup(props, { emit }) {
            const store = useMainStore()

            const fileInput = ref(null)
            const inputValue = ref(null)
            const apiURL = process.env.VUE_APP_API_URL
            const photo = ref(apiURL + 'static/' + store.student.photo)

            const showImageCropper = ref(false)
            const imageToCrop = ref({
                src: '',
                type: ''
            }) 

            const processImage = (image) => {
                store.student.photo = image
                photo.value = image
                showImageCropper.value = false
            }

            const formattedBirthDate = computed({
                get() {
                    if (!store.student.birthday.includes('T'))
                        return store.student.birthday
                    return formateDate(store.student.birthday)
                },
                set(value) {
                    store.student.birthday = new Date(value).toISOString().split('.')[0]
                }
            })

            const loadImage = (event) => {
                const { files } = event.target
                if (files && files[0]) {
                    if (imageToCrop.value.src) {
                        URL.revokeObjectURL(imageToCrop.value.src)
                    }
                    const blob = URL.createObjectURL(files[0])
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        imageToCrop.value.src = blob
                        imageToCrop.value.type = getMimeType(e.target.result, files[0].type)
                        showImageCropper.value = true
                    }
                    reader.readAsArrayBuffer(files[0])
                }
            }

            const age = computed({
                get() {
                    return new Date(props.birthDate) < new Date() ? new Date().getFullYear() - new Date(props.birthDate).getFullYear() : '—'
                }
            }) 
            
            const uploadPhoto = () => {
                fileInput.value.click()
            }

            onMounted(() => {
                console.log(typeof photo.value)
                if(photo.value === null || photo.value === 'null') {
                    photo.value = require('../../assets/student_photo_placeholder.svg')
                }
            })

            watch(() => props.studentPhoto, (first, second) => {
                photo.value = first
                inputValue.value = null
            })

            return {
                uploadPhoto,
                photo,
                fileInput,
                inputValue,
                age,
                formattedBirthDate,
                formateDate,
                store,
                showImageCropper,
                imageToCrop,
                processImage,
                loadImage
            }
        },

        components: {
            ImageCropper
        }
    }
</script>

<style scoped>

    .student-logo {
        -webkit-filter: drop-shadow(0px 0px 10px #222);
        filter: drop-shadow(0px 0px 10px #222);
    }

    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23FFFFFF" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

    .avenir-bold {
        font-family: 'Avenir Cyrillic Bold';
    }

    .avenir-standart {
        font-family: 'Avenir Cyrillic';
    }

    .marquee-container {
        overflow: hidden;
        position: relative;
        height: 12vw;
        width: 90%;
        margin: 0 auto;

    }

    .marquee {
        position: absolute;
        white-space: nowrap;
        height: 15vw;
        animation: marquee 660s linear infinite;
    }


    .marquee > * {
        animation-delay: 25s;
    }


    @keyframes marquee {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%);
        }
    }

</style>