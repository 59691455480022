<template>
    <div>
        <div @click="$router.push('/')" class="flex text-[#FFF] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-white">
                Назад
            </div>
        </div>

        <div class="text-[#FFF] mt-[10px]">
            <div class="text-center">
                ПОВІДОМЛЕННЯ
            </div>
            <div class="w-[90%] mx-auto mt-[10px] h-px bg-[#FFF]"></div>
        </div>

        <div>

            <div v-for="month in messagesByMonth" class="mt-5 mb-10">
                <div class="text-center text-white text-[13px] font-light leading-tight">{{ month.month_name }}</div>

                <div v-for="message in month.messages" @click="selectMessage(message)" class="w-[90%] mt-3 mx-auto relative flex h-[58px] bg-[#47484966] bg-opacity-5 rounded-[8px] border-[1px] border-[#8C8C8C33] mb-2">
                    <div v-if="message.sender == 'trainer'" class="h-full rounded-l-[8px] bg-green-400 w-[7px]"></div>
                    <div v-if="message.sender == 'system'" class="h-full rounded-l-[8px] bg-[#F9245A] w-[7px]"></div>
                    <div v-if="message.sender == 'admin'" class="h-full rounded-l-[8px] bg-green-400 w-[7px]"></div>
                    <div class="w-[80%] flex pl-[4px] text-white align-middle items-center justify-center">
                        <div class="text-white text-[9px] mr-[8px] font-light leading-tight w-[20%]">{{ message.date }}</div>
                        <div class="text-[10px] align-middle w-[70%] overflow-hidden h-[85%] flex items-center text-[#fff]">{{ message.text.slice(0, 60) }}{{ message.text.length > 60 ? '...' : '' }}</div>
                    </div>
                    <div v-if="message.sender == 'system'" class="flex justify-center items-center w-[18.5%] h-[11px] top-[50%] absolute right-[5px] translate-y-[-50%] bg-[#F9245A] rounded-full">
                        <div class="w-[42px] h-2.5 relative bottom-[1px] text-center flex items-center justify-center text-white text-[9px] font-normal leading-tight">система</div>
                    </div>
                    <div v-if="message.sender == 'trainer'" class="flex justify-center items-center w-[18.5%] h-[11px] top-[50%] absolute right-[5px] translate-y-[-50%] bg-green-400 rounded-full">
                        <div class="w-[42px] h-2.5 relative bottom-[1px] text-center flex items-center justify-center text-white text-[9px] font-normal leading-tight">тренер</div>
                    </div>
                </div>
                
            </div>
            


        </div>
    </div>
</template>

<script>
    import { ref, watch, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useMainStore } from '../store/mainStore'
    import { getAllStudentMessages } from '../services/apiRequests'

    export default {
        name: 'MessagesView',

        setup() {
            const store = useMainStore()
            const router = useRouter()

            const messagesByMonth = ref([])

            const selectMessage = (message) => {
                store.currentMessage = message
                router.push('/current-message')
            }

            const formateMonthAndYear = (date) => {
                const month = date.slice(5, 7)
                const year = date.slice(0, 4)

                switch (month) {
                    case '01':
                        return `СІЧЕНЬ ${year}`
                    case '02':
                        return `ЛЮТИЙ ${year}`
                    case '03':
                        return `БЕРЕЗЕНЬ ${year}`
                    case '04':
                        return `КВІТЕНЬ ${year}`
                    case '05':
                        return `ТРАВЕНЬ ${year}`
                    case '06':
                        return `ЧЕРВЕНЬ ${year}`
                    case '07':
                        return `ЛИПЕНЬ ${year}`
                    case '08':
                        return `СЕРПЕНЬ ${year}`
                    case '09':
                        return `ВЕРЕСЕНЬ ${year}`
                    case '10':
                        return `ЖОВТЕНЬ ${year}`
                    case '11':
                        return `ЛИСТОПАД ${year}`
                    case '12':
                        return `ГРУДЕНЬ ${year}`
                }
            }

            onMounted(async () => {
                const messages = (await getAllStudentMessages(store.student.student_id)).data
                console.log('messages res', messages)
                messagesByMonth.value = []

                messages.forEach(message => {
                    const month = message.date.slice(0, 7)
                    const monthIndex = messagesByMonth.value.findIndex(month => month.month_code == message.date.slice(0, 7))
                    if (monthIndex == -1) {
                        messagesByMonth.value.unshift({
                            month_name: formateMonthAndYear(message.date),
                            month_code: month,
                            messages: [{
                                date: message.date.split('T')[0].split('-').reverse().join('.'),
                                text: message.text,
                                sender: message.sender_type
                            }]
                        })
                    } else {
                        messagesByMonth.value[monthIndex].messages.unshift({
                            date: message.date.split('T')[0].split('-').reverse().join('.'),
                            text: message.text,
                            sender: message.sender_type
                        })
                    }
                })

                console.log(messagesByMonth.value)
            })

            return {
                messagesByMonth,
                selectMessage 
            }

        }
    }
</script>

<style lang="scss" scoped>
    .multiline-truncate::after {
        content: "...";
        
    }

</style>