<template>
    <div class="pl-[3vw] mt-[75px] flex" v-if="loading">
        <Header 
            class="fixed top-[10px] left-[50%] translate-x-[-50%]"
            :class="{
                'ml-[122px] w-[80%]' : screenWidth > 1300,
                'ml-[30px] w-[85%]' : screenWidth < 1300,
            }"    
        />
        <AddPaymentPopup 
            v-if="paymentPopupFlag"
            @close = "paymentPopupFlag = false"
        />
        <!-- <div class="text-white f   ont-semibold text-[20px] absolute top-[16px]">Фінанси</div> -->
        <div class="w-[75.4%]">

            <div class="w-[100%]">
                <div class="text-white font-medium relative top-[18px] text-[13px] ml-[20px]">ОПЛАТИ <span class="font-thin">(по місяцях)</span></div>
                <div class="w-full flex justify-end">
                    <div class="text-center rounded-[4px] border-[#4EAE3B] text-[13px] w-[69px] h-[19px] font-semibold border-[1px] text-[#4EAE3B]">
                        <span class="relative bottom-[1px]">{{ currentYear }}</span>
                    </div>
                </div>
                <div class="w-full flex items-center">
                    <button @click="previousYear" class="ml-[0.1vw] h-[1vw]">
                        <img src="../assets/left_arrow.svg" class="arrow-height">
                    </button>
                    <div class="flex h-[100px] mt-[20px] justify-between align-bottom items-end w-full">
                        <div v-for="monthOutlay in outlays" class="flex flex-col justify-end align-bottom items-center w-[8%]">
                            <div class="text-[#FFF] text-xs">
                                {{ monthOutlay.amount }}
                            </div>
                            <div 
                                :style="[`height: ${monthOutlay.colHeight > 0 ? monthOutlay.colHeight : 1}px;`]"
                                class="w-[60px] bg-[#4EAE3B]"
                            ></div>
                            <div class="text-xs text-[#FFF]">
                                {{ monthOutlay.month }}
                            </div>
                        </div>
                    </div>
                    <button @click="nextYear" class="ml-[0.1vw] h-[1vw]">
                        <img src="../assets/right_arrow.svg" class="arrow-height">
                    </button>
                </div>
            </div>
    
            <div class="w-[100%] mt-[50px] overflow-y-auto">
                <!-- <div class="text-white font-medium mb-[10px] text-[13px] ml-[20px]">МОЇ ОПЛАТИ</div> -->
                <div class="max-h-500px overflow-y-auto message-box">
                    <div class="w-full">
                        <div v-if="screenWidth > 1500" class="text-white px-[20px] h-[52px] w-full flex justify-between">
                            <div class="w-[5%] text-center">#</div>
                            <div class="w-[10%] text-center">Дата</div>
                            <div class="w-[10%] text-center relative left-[20px]">Сума</div>
                            <div class="w-[13%] text-center relative left-[20px]">Аванс</div>
                            <div class="w-[18.2%] text-center">Тариф</div>
                            <div class="text-end w-[45%]">ID транзакції</div>
                        </div>
                        <div v-else class="text-white px-[20px] h-[52px] w-full flex justify-between">
                            <div class="w-[5%] text-center">#</div>
                            <div class="w-[20%] text-center">Дата</div>
                            <div class="w-[15%] text-center relative left-[20px]">Сума</div>
                            <div class="w-[21.8%] text-center relative left-[20px]">Аванс</div>
                            <div class="w-[18.2%] text-center">Тариф</div>
                            <div class="text-end w-[20%]">ID транзакції</div>
                        </div>
                        <div v-if="screenWidth > 1500" class="h-[60vh] overflow-y-auto message-box px-[20px]">
                            <div v-if="incomes.length > 0" v-for="(income, id) in incomes" class="border-b-[1px] border-opacity-10 flex justify-between items-center border-[#E4E4EF] h-[52px]">
                                <div class="text-[#FFF] text-[15px] w-[5%] text-center">{{ id + 1 }}.</div>
                                <div class="text-[#FFF] text-[15px] w-[10%] text-center">{{ getDate(income.date) }}</div>
                                <div class="text-[15px] w-[10%] text-end text-[#4EAE3B]">{{ income.amount.toFixed(2) }} грн</div>
                                <div 
                                    class="text-[15px] w-[11.8%] text-end"
                                    :class="{
                                        'text-[#4EAE3B]': income.prepaid_income > 0,
                                        'text-[#F9245A]': income.prepaid_income < 0,
                                        'text-[#5e5d59]': income.prepaid_income == 0,

                                    }"
                                >{{ income.prepaid_income > 0 ? '+' : '' }}{{ income.prepaid_income.toFixed(2) }} грн.</div>
                                <div 
                                    class="text-[15px] text-center w-[18.2%] relative left-[10px] text-white"
                                >{{ income.tariff_name }}</div>
                                <div class="text-[#FFF] text-[15px] text-end cursor-pointer w-[45%] opacity-40" @click="copyToClipboard(income.tx_id)">#{{ income.tx_id }}</div>
                            </div>
                            <div v-else>
                                <div colspan="6" class="w-full">
                                    <div class="text-[#FFF] text-[15px] flex justify-center items-center border-[1px] border-[#FFF] bg-[#FFF] bg-opacity-20 h-[52px] rounded-[10px]">Нема транзакцій</div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="h-[60vh] overflow-y-auto message-box px-[20px]">
                            <div v-if="incomes.length > 0" v-for="(income, id) in incomes" class="border-b-[1px] border-opacity-10 flex justify-between items-center border-[#E4E4EF] h-[52px]">
                                <div class="text-[#A1D9F7] text-[15px] w-[5%] text-center">{{ id + 1 }}.</div>
                                <div class="text-[#A1D9F7] text-[15px] w-[20%] text-center">{{ getDate(income.date) }}</div>
                                <div class="text-[#A1D9F7] text-[15px] w-[15%] text-end">{{ income.amount.toFixed(2) }} грн</div>
                                <div 
                                    class="text-[15px] w-[21.8%] text-end"
                                    :class="{
                                        'text-[#4EAE3B]': getTxType(income.label) === 'готівка',
                                        'text-[#F8FF82]': getTxType(income.label) === 'безготівка'
                                    }"
                                >{{ getTxType(income.label) }}</div>
                                <div 
                                    class="text-[15px] text-center w-[18.2%] relative left-[10px]"
                                    :class="{
                                        'text-[#4EAE3B]': getTxType(income.label) === 'готівка',
                                        'text-[#F8FF82]': getTxType(income.label) === 'безготівка'
                                    }"
                                >{{ receiverType(income.label) }}</div>
                                <div class="text-[#A1D9F7] text-[15px] text-end cursor-pointer w-[20%] opacity-40" @click="copyToClipboard(income.tx_id)">#{{ income.tx_id.slice(0, 10) }}...</div>
                            </div>
                            <div v-else>
                                <div colspan="6" class="w-full">
                                    <div class="text-[#A1D9F7] text-[15px] flex justify-center items-center border-[1px] border-[#A1D9F7] bg-[#A1D9F7] bg-opacity-20 h-[52px] rounded-[10px]">Нема транзакцій</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-[24.6%] relative ml-[30px]">
            <button @click="newPayment" class="bg-[#4EAE3B] mb-[20px] text-white ml-[45px] w-[212px] mt-[19px] py-1 text-center text-[14px] font-medium rounded-[5px] h-[30px] right-[84px]">
                Сплатити
            </button>
            <div v-if="screenWidth > 1500" class="w-[85%] h-[78vh] flex flex-col px-[45px] justify-between right-0 top-[60px] border-[#FFF] border-l-[1px]">
                <div class="h-[60%]">
                    <div class="text-[#4EAE3B] text-[13px] pr-[10px] mb-[15px]">
                        <img src="../assets/question_icon.svg" class="w-[18px] relative right-[30px] top-[21px]">
                        <span class="text-[32px] leading-[0] relative top-[4.5px]">·</span> На діаграмі показано нарахування/списання за місяці у яких гравець відвідував тренування
                    </div>
                    <div class="text-[#4EAE3B] text-[13px]  pr-[10px] mb-[15px]">
                        <span class="text-[32px] leading-[0] relative top-[4.5px]">·</span> Оплата за місяць нараховується у випадку, якщо гравець був присутній хоча б на трьох тренуваннях
                    </div>
                    <div class="text-[#4EAE3B] text-[13px]  pr-[10px] mb-[15px]">
                        <span class="text-[32px] leading-[0] relative top-[5.5px]">·</span> Способи проведення оплат:<br>
                        -  прямо тут - з кабінету гравця;<br>
                        -  готівкою вашому тренеру;<br>
                        -  готівкою в офісі клубу;<br>
                    </div>
                    <div class="text-[#F9245A] text-[13px] pr-[30px] mb-[15px]">
                        <img src="../assets/attention_icon.svg" class="w-[18px] relative right-[30px] top-[19px]">
                        Не рекомендуємо робити оплати через касу банків, оскільки у такому випадку не відбудеться автоматичне зарахування коштів на баланс. Також вам прийдеться передати квітанцію про оплату представнику клубу для проведення перевірки поступлення коштів та коригування балансу гравця в ручному режимі
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-else>
            <button @click="paymentPopupFlag = true" class="bg-[#4EAE3B] text-white w-[212px] py-1 text-center text-[14px] font-medium rounded-[5px] h-[30px]">
                Сплатити
            </button>
        </div> -->
    </div>
    <div v-else>
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent absolute top-[50%] left-[50%] ml-[30px]"></div>
    </div>
</template>

<script>
    import { useMainStore } from '../store/mainStore' 
    import { getIncomesByBalanceId, getOutlaysByBalanceId, getAllRealIncomeTariffs } from '../services/apiRequests'
    import { ref, onMounted, onBeforeMount, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import AddPaymentPopup from '../components/FinanceBlock/AddPaymentPopup.vue'
    import Header from '../components/Header.vue'

    function getMonthName(date) {
        const shortMonths = ['Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру']
        const monthIndex = date.getMonth()
        return shortMonths[monthIndex]
    }

    export default {
        name: 'FinancePage',

        setup() {
            const incomes = ref([])
            const outlays = ref([])
            const store = useMainStore()
            const paymentPopupFlag = ref(false)
            const router = useRouter()
            const currentYear = ref(new Date().getFullYear())
            const loading = ref(false)
            const getDate = (date) => {
                const newDate = new Date(date)
                const day = newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate()
                const month = newDate.getMonth() < 10 ? `0${newDate.getMonth()}` : newDate.getMonth()
                return `${day}.${month}.${newDate.getFullYear()}`
            }
            const screenWidth = ref(window.innerWidth)

            const getTxType = (label) => {
                if (label.includes('тренер') || label.includes('Cashbox')) {
                    return 'готівка'
                } else if (label.includes('Wayforpay') || label.includes('Bank') || label.includes('Liqpay') || label.includes('IPay')) {
                    return 'безготівка'
                }
            }

            const copyToClipboard = (value) => {
                navigator.clipboard.writeText(value);
            };

            onBeforeMount(async () => {
                console.log('before mount')
                const studentRes = await store.getStudentData()
                console.log(studentRes)
                if (!studentRes) {
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                    router.go()
                    return
                }
                await store.getGroupData()
                await store.getTrainerData()
                await store.getStudentIncomes()
                // await store.getBalanceState()
                await store.getTelegramBotSubscribed()
                console.log('store.student.group_id ----- ', store.student.group_id.length > 0)
                if (store.student.group_id.length > 0) {
                    await store.getPlanedTrainings()
                    await store.getAllGroupStudents()
                }
                await store.getStudentMessages()
                await store.getStudentBodyParameters()
                await store.getStudentCompletedTrainings()
                loading.value = true

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                    console.log(screenWidth.value)
                });
                await uploadOutlays()
            })

            const receiverType = (label) => {
                if (label.includes('тренер')) {
                    return 'тренер'
                } else if (label.includes('Wayforpay')) {
                    return 'wayforpay'
                } else if (label.includes('Bank')) {
                    return 'банк'
                } else if (label.includes('Cashbox')) {
                    return 'каса'
                } else if (label.includes('Liqpay')) {
                    return 'liqpay'
                } else if (label.includes('IPay')) {
                    return 'ipay'
                }
            }

            watch(() => window.innerWidth, (newWidth) => {
                screenWidth.value = newWidth
            })

            const nextYear = async () => {
                currentYear.value += 1
                await uploadOutlays()
            }

            const previousYear = async () => {
                currentYear.value -= 1
                await uploadOutlays()
            }

            const uploadOutlays = async () => {
                outlays.value = []
                const shortMonths = ['Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру']
                const responseIncomes = await getAllRealIncomeTariffs(store.student.student_id)
                incomes.value = responseIncomes.data.transactions.sort((a, b) => new Date(b.date) - new Date(a.date))
                const responseOutlays = await getAllRealIncomeTariffs(store.student.student_id)
                let maxAmount = 0
                shortMonths.forEach(month => {
                    let currentMonthOutlays = 0
                    responseOutlays.data.transactions.forEach(outlay => {
                        const outlayDate = new Date(outlay.date)
                        if (month === getMonthName(outlayDate)) {
                            currentMonthOutlays += outlay.amount
                        }
                    })

                    if(currentMonthOutlays > maxAmount) {
                        maxAmount = currentMonthOutlays
                    }

                    outlays.value.push({
                        month,
                        amount: currentMonthOutlays
                    })
                })
                outlays.value = outlays.value.map(outlay => {
                    const amountPercentOfMax = (100 * outlay.amount) / maxAmount
                    const colHeight = (amountPercentOfMax * 70) / 100

                    return {
                        ...outlay,
                        colHeight
                    }
                })
            }

            const newPayment = () => {
                // paymentPopupFlag = true
                alert('Платіжна система не підключена. Оплачувати наразі неможливо')
            }

            return {
                incomes,
                outlays,
                getDate,
                getTxType,
                receiverType,
                paymentPopupFlag,
                copyToClipboard,
                currentYear,
                nextYear,
                previousYear,
                loading,
                screenWidth,
                newPayment
            }
        },

        components: {
            AddPaymentPopup,
            Header
        }
    }
</script>

<style lang="scss" scoped>

</style>