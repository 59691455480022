<template>
    <div>
        <div @click="$router.push('/messages')" class="flex text-[#FFF] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-white">
                Назад
            </div>
        </div>

        <div class="text-[#FFF] mt-[10px]">
            <div class="text-center">
                ДЕТАЛЬНО
            </div>
            <div class="w-[90%] mx-auto mt-[10px] h-px bg-[#FFF]"></div>
        </div>

        <div class="w-[90%] flex justify-between mx-auto mt-[17px]">
            <div class="text-white">
                {{store.currentMessage.date}}
            </div>
            <div v-if="store.currentMessage.sender == 'system'" class="flex justify-center items-center px-[21px] bg-[#F9245A] rounded-full">
                <div class="py-[1px] text-center flex items-center justify-center text-white text-[12px] font-normal leading-tight">система</div>
            </div>
            <div v-if="store.currentMessage.sender == 'trainer'" class="flex justify-center items-center px-[21px] bg-[#4EAE3B] rounded-full">
                <div class="py-[1px] text-center flex items-center justify-center text-white text-[12px] font-normal leading-tight">тренер</div>
            </div>
        </div>

        <div class="w-[90%] mt-5 mx-auto relative flex h-[58px] bg-[#47484966] bg-opacity-5 rounded-[8px] border-[1px] border-[#8C8C8C33] mb-2">
            <div v-if="store.currentMessage.sender == 'system'" class="h-full rounded-l-[8px] bg-[#F9245A] w-[7px] mr-[10px]"></div>
            <div v-if="store.currentMessage.sender == 'trainer'" class="h-full rounded-l-[8px] bg-[#4EAE3B] w-[7px] mr-[10px]"></div>
            <div class="text-[12px] align-middle w-[80%] overflow-hidden h-[85%] flex items-center text-[#fff]">{{ store.currentMessage.text }}</div>
        </div>

    </div>
</template>

<script>
    import { useMainStore } from '../store/mainStore'

    export default {
        name: 'current-message-view',

        setup() {
            const store = useMainStore()

            return {
                store
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>