import { defineStore } from 'pinia'
import { checkStudentAuthData, getGroupById, getOutlaysByBalanceId, getStudentBodyParametersRequest, getTrainerById, getStudentCompletedTrainings, getAllRealIncomeTariffs, getPlanedTrainingsByGroupId, getIncomesByBalanceId, getBalanceState, updateStudentPersonalDataAgreementsRequest, updateSchoolRulesAgreementsRequest, checkBotSubscribe, getStudentsByGroupId, getCompletedTrainingsByGroupId } from '../services/apiRequests'

export const useMainStore = defineStore('main', {
    state: () => ({
        student: {},
        group: {},
        trainer: {},
        planedTrainingsData: [],
        studentIncomes: [],
        studentOutlays: [],
        studentBalance: 0,
        isBotSubscribed: false,
        studentAttendedTrainings: [],
        studentMissedTrainings: [],
        groupStudents: [],
        studentsHeights: [],
        studentsWeights: [],
        currentMessage: [],

        completedTrainingsByGroup: [],

        imageToCrop: null,
    }),
    
    getters: {
        studentAge: (state) => new Date().getFullYear() - new Date(state.student.birthday).getFullYear(),
        trainerName: (state) => `${state.trainer.surname} ${state.trainer.name[0]}.`,
        studentName: (state) => `${state.student.name} ${state.student.surname}`,
        mappedDatesPlanningTrainings: (state) => state.planedTrainingsData.map(training => new Date(training.date).toISOString().split('T')[0]),
        studentPhoto: (state) => process.env.VUE_APP_API_URL + 'static/' + state.student.wallpaper_photo,
        trainingsAttendedProcent: (state) => state.studentAttendedTrainings.length * 100 / (state.studentAttendedTrainings.length + state.studentMissedTrainings.length),
        startTrainingDate: (state) => state.student.start_training?.split('T')[0].split('-').reverse().join('.'),
        passportEndDate: (state) => state.student.passport_end_date?.split('T')[0].replaceAll('-', '.'),
    },
    
    actions: {
        async readFileAsDataURL(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = (e) => resolve(e.target.result)
                reader.onerror = (e) => reject(e)
                reader.readAsDataURL(file)
            })
        },

        async getStudentData() {
            const studentRes = await checkStudentAuthData(localStorage.studentLogin, localStorage.studentPassword)
            if (studentRes.data.status == 'error') {
                return false
            }
            this.student = studentRes.data.student

            return true
        },
        async getStudentCompletedTrainings() {
            const res = await getStudentCompletedTrainings(this.student.student_id)
            this.studentAttendedTrainings = res.data.trainings_attended
            this.studentMissedTrainings = res.data.missed_trainings
        },
        async getGroupData() {
            if (this.student.group_id) {
                const groupRes = await getGroupById(this.student.group_id)
                this.group = groupRes.data
            } else {
                this.group = {}
            }
        },
        async getAllGroupStudents() {
            const studentsRes = await getStudentsByGroupId(this.student.group_id)
            this.groupStudents = studentsRes.data
        },
        async getTrainerData() {
            if (this.group.trainer_id) {
                const trainerRes = await getTrainerById(this.group.trainer_id)
                this.trainer = trainerRes.data
            } else {
                this.trainer = {
                    name: '—',
                    surname: ''
                }
            }
        },
        async getPlanedTrainings() {
            if (this.student.group_id) {
                const planedTrainingsRes = await getPlanedTrainingsByGroupId(this.group.group_id)
                this.planedTrainingsData = planedTrainingsRes.data
            } else {
                this.planedTrainingsData = []
            }
        },
        async getStudentIncomes() {
            const incomesRes = await getAllRealIncomeTariffs(this.student.student_id)
            this.studentIncomes = incomesRes.data.transactions
        },

        async getStudentOutlays() {
            const outlaysRes = await getAllRealIncomeTariffs(this.student.student_id)
            this.studentOutlays = outlaysRes.data.transactions
            console.log('this.studentOutlays', outlaysRes)
        },

        async getStudentBodyParameters() {
            this.studentsHeights = []
            this.studentsWeights = []
            const res = (await getStudentBodyParametersRequest(this.student.student_id)).data
            for(let i = 0; i < 12; i++) {
                res.find(item => new Date(item.date).getMonth() == i) ? this.studentsHeights.push(res.find(item => new Date(item.date).getMonth() == i).height) : this.studentsHeights.push(0)
                res.find(item => new Date(item.date).getMonth() == i) ? this.studentsWeights.push(res.find(item => new Date(item.date).getMonth() == i).weight) : this.studentsWeights.push(0)
            }            
        },
        async getBalanceState() {
            const balanceStateRes = await getBalanceState(this.student.balance_id)
            this.studentBalance = balanceStateRes.data.balance_state
        },
        async updateUserPersonalDataAgreement() {
            await updateStudentPersonalDataAgreementsRequest(this.student.student_id)
            this.student.personal_data_agreement = !this.student.personal_data_agreement
        },
        async updateSchoolRulesAgreement() {
            await updateSchoolRulesAgreementsRequest(this.student.student_id)
            this.student.club_rules_agreement = !this.student.club_rules_agreement
        },
        async getTelegramBotSubscribed() {
            const res = await checkBotSubscribe(this.student.student_id)
            if(res.data.status == 'success') {
                this.isBotSubscribed = true
            } else {
                this.isBotSubscribed = false
            }
        },
        async getCompletedTrainingsByStudentGroup() {
            const res = await getCompletedTrainingsByGroupId(this.student.group_id)
            this.completedTrainingsByGroup = res.data
        }
    }
})