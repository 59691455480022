<template>
    <div class="fixed h-full w-full top-0 left-0 bg-[#252526] z-[180] bg-opacity-40 backdrop-blur-[10px]" @click="$emit('close')"></div>
    <!-- <div class="z-[200] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[300px] bg-[#142552] px-[10px] py-[5px] rounded-md">
        <div class="w-full flex justify-center">
            <img src="../../assets/robot_icon.svg" class="w-9">
        </div>
        <div class="text-center mt-4 py-2 px-[10px] rounded-lg font-normal text-[15px] text-[#A1D9F7]">
            Перейдіть до бота та натисніть кнопку "Почати"
        </div>
        <div @click="linkToBot" class="bg-[#A1D9F7] flex justify-center items-center mt-3 px-3 w-full h-[40px] rounded-md">
            <div class="w-4 mx-2"></div>
            <div class="font-bold text-[16px] text-[#000] text-center">Лінк на бота</div>
            <div class="flex items-center"><img src="../../assets/robot_icon.svg" class="w-5 mx-2 mt-1"></div>
        </div>
        <div v-if="clickedbotLink">
            <div class="text-center  mt-3 py-2 px-[10px] rounded-lg font-normal text-[15px] text-[#A1D9F7]">
                Тепер клікніть "Перевірити"
            </div>
            <div @click="checkBot" class="bg-[#4EAE3B] mt-3 flex justify-center items-center w-full h-[40px] rounded-md">
                <div class="font-bold text-[16px] text-[#FFF]">Перевірити</div>
            </div>
        </div>
    </div> -->

    <div class="z-[200] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[95%] border-[1px] border-[#4EAE3B] shadow-md shadow-black bg-[#252526] px-[15px] py-[15px] rounded-[12px]">
        <div v-if="!clickedbotLink && !store.isBotSubscribed">
            <div class="w-full flex justify-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.0477 3.05293C18.8697 0.707363 2.48648 6.4532 2.50001 8.551C2.51535 10.9299 8.89809 11.6617 10.6672 12.1581C11.7311 12.4565 12.016 12.7625 12.2613 13.8781C13.3723 18.9305 13.9301 21.4435 15.2014 21.4996C17.2278 21.5892 23.1733 5.342 21.0477 3.05293Z" stroke="#4EAE3B" stroke-width="1.5"/>
                    <path d="M11.5 12.5L15 9" stroke="#4EAE3B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="text-center mt-4 py-2 px-[10px] rounded-lg font-normal text-[13px] text-[#FFF]">
                Для підключення сповіщень у телеграм, натисніть підключити Telegram-Bot,  вас буде перенаправлено у застосунок Telegram, де завершиться активація
            </div>
            <div @click="linkToBot" class="bg-[#4EAE3B] flex justify-center items-center mt-3 px-3 w-full h-[40px] rounded-md">
                <div class="font-bold text-[16px] text-[#FFFFFF] text-center">Підключити Telegram-Bot</div>
            </div>
        </div>
        <div v-if="clickedbotLink && !store.isBotSubscribed">
            <div class="text-center py-5 px-[10px] rounded-lg font-normal text-[15px] text-[#FFF]">
                Щоб переконатися, що все пройшло успішно, настисніть кнопку перевірити
            </div>
            <div @click="checkBot" class="bg-[#4EAE3B] mt-3 flex justify-center items-center w-full h-[40px] rounded-md">
                <div class="font-bold text-[16px] text-[#FFF]">Перевірити</div>
            </div>
        </div>
        <div v-if="store.isBotSubscribed">
            <div class="flex w-full justify-center">
                <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 10C1 8.89543 1.89543 8 3 8C4.65685 8 6 9.34315 6 11V15C6 16.6569 4.65685 18 3 18C1.89543 18 1 17.1046 1 16V10Z" stroke="#4EAE3B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.4787 5.30626L14.2124 6.16634C13.9942 6.87111 13.8851 7.22349 13.969 7.5018C14.0369 7.72695 14.1859 7.92102 14.389 8.04871C14.64 8.20655 15.0197 8.20655 15.7791 8.20655H16.1831C18.7532 8.20655 20.0382 8.20655 20.6452 8.9673C20.7145 9.05425 20.7762 9.14669 20.8296 9.24367C21.2965 10.0921 20.7657 11.2351 19.704 13.5211C18.7297 15.6189 18.2425 16.6678 17.338 17.2852C17.2505 17.3449 17.1605 17.4013 17.0683 17.4541C16.116 18 14.9362 18 12.5764 18H12.0646C9.20572 18 7.77628 18 6.88814 17.1395C6 16.2789 6 14.8939 6 12.1239V11.1503C6 9.69464 6 8.96678 6.25834 8.3006C6.51668 7.63441 7.01135 7.08664 8.00069 5.99112L12.0921 1.46056C12.1947 1.34694 12.246 1.29012 12.2913 1.25075C12.7135 0.88328 13.3652 0.924643 13.7344 1.34235C13.774 1.3871 13.8172 1.44991 13.9036 1.57554C14.0388 1.77205 14.1064 1.87031 14.1654 1.96765C14.6928 2.83913 14.8524 3.87436 14.6108 4.85715C14.5838 4.96692 14.5488 5.0801 14.4787 5.30626Z" stroke="#4EAE3B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="text-center  mt-3 px-[10px] rounded-lg font-normal text-[13px] text-[#FFF]">
                <div>Чудово!</div> 
                <div>Вам успішно підключено сповіщення у Telegram</div>
            </div>
            <div @click="$emit('close')" class="bg-[#FFF] mt-3 flex justify-center items-center w-full h-[40px] rounded-md">
                <div class="font-bold text-[16px] text-[#142552]">Закрити</div>
            </div>
        </div>
    </div>

</template>

<script>
    import { ref } from 'vue'
    import { checkBotSubscribe } from '@/mobileApp/services/apiRequests'
    import { useMainStore } from '../../store/mainStore'

    export default {
        name: 'TelegramSubscriptionBlock',

        setup(props, { emit }) {
            const clickedbotLink = ref(false)
            const store = useMainStore()
            const linkToBot = () => {
                window.open(process.env.VUE_APP_BOT_ALERTS_URL + `?start=${store.student.student_id}`, '_parent')
                clickedbotLink.value = true
            }

            const checkBot = async () => {
                const res = await checkBotSubscribe(store.student.student_id)
                if (res.data.status == 'success') {
                    store.isBotSubscribed = true
                }

            }

            return {
                linkToBot,
                clickedbotLink,
                checkBot,
                store
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>