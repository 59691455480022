<template>
  <div class="mt-[74px]">
    <div class="text-white flex justify-between mb-[27px] items-center">
      <div class="text-[9px] tracking-[0.2em]">//////////////</div>
      <div class="text-[15px] font-semibold">КАЛЕНДАР ТРЕНУВАНЬ</div>
      <div class="text-[9px] tracking-[0.2em]">//////////////</div>
    </div>

    <div class="flex justify-center items-center">
      <button @click="previousMonth" class="mr-4">
      
        <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>


      </button>
      <span class="text-[13px] font-semibold text-[#fff]">{{ currentMonth.toUpperCase() }} {{ currentYear }}</span>
      <button @click="nextMonth" class="ml-4">
      
        <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L8.43043 8.17424C9.18986 8.90341 9.18986 10.0966 8.43043 10.8258L2 17" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>

      
      </button>
    </div>
    <table class="mx-auto mt-2">
      <thead class="border-b-2 border-[#fff]">
        <tr>
          <th class="text-[#4EAE3B] text-lg" width="45px">Пн</th>
          <th class="text-[#4EAE3B]" width="45px">Вт</th>
          <th class="text-[#4EAE3B]" width="45px">Ср</th>
          <th class="text-[#4EAE3B]" width="45px">Чт</th>
          <th class="text-[#4EAE3B]" width="45px">Пт</th>
          <th class="text-[#F9245A]" width="45px">Сб</th>
          <th class="text-[#F9245A]" width="45px">Нд</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="week in weeks">
          <td 
            v-for="day in week" 
            :key="day.id" 
            class="text-center text-lg px-1" 
            :class="
              {
                'text-[#F9245A]' : day.weekDay == 5 || day.weekDay == 6,
                'text-[#fff]' : day.weekDay != 5 && day.weekDay != 6
              }
            "
            height="40px" width="40px"
          >
            <div 
              @click="emitPlanedTraining(day)"
              class="rounded-xl bg-opacity-20 h-full align-middle text-center pt-[4px]"
              :class="{
                'bg-[#ABD3EC] border-[2.5px] border-[#DFF726]' : checkIfTrainingIsPlanned(day),
                'bg-[#ABD3EC] border-[2.5px] border-[#F9245A]' : checkIfTrainingIsMissed(day) && !checkIfTrainingIsAttended(day),
                'bg-[#ABD3EC] border-[2.5px] border-[#4EAE3B]' : checkIfTrainingIsAttended(day),
                'font-bold' : checkCurrentDay(day),
              }"
            >
              {{ day.monthDay }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import { getWeeksInMonth, addingMatchOfTheDayOfTheWeekAndItsNumberInMonth, formatWeeks } from './service/helpers'

  export default {
    data() {
      return {
        currentMonth: '',
        currentYear: '',
        weeks: [],
        currentDate: new Date()
      }
    },

    props: {
      planedTrainings: {
        type: Array,
        default: () => []
      },
      attendedTrainings: {
        type: Array,
        default: () => []
      },
      missedTrainings: {
        type: Array,
        default: () => []
      },
      completedTrainings: {
        type: Array,
        default: () => []
      }
    },

    created() {
      this.currentDate = new Date()
      this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
      this.currentYear = this.currentDate.getFullYear()
      this.weeks = formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate)))
      console.log('formattedWeeks -------- ', this.weeks)
    },
    methods: {
      previousMonth() {
        this.currentDate = new Date(this.currentYear, this.currentDate.getMonth() - 1)
        this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
        this.currentYear = this.currentDate.getFullYear()
        this.weeks = formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate)))
        console.log('formattedWeeks -------- ', this.weeks)
      },
      nextMonth() {
        this.currentDate = new Date(this.currentYear, this.currentDate.getMonth() + 1)
        this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
        this.currentYear = this.currentDate.getFullYear()
        this.weeks = formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate)))
      },
      checkIfTrainingIsPlanned(day) {
        for (let i = 0; i < this.planedTrainings.length; i++) {
          if (new Date(this.planedTrainings[i].date).toISOString().split('T')[0] === day?.dayDate?.toISOString().split('T')[0]) {
            return true
          }
        }
        return false
      },
      checkIfTrainingIsAttended(day) {
        let dayNum = day?.dayDate?.getDate() > 9 ? day?.dayDate?.getDate() : '0' + day?.dayDate?.getDate()
        let monthNum = day?.dayDate?.getMonth() + 1 > 9 ? day?.dayDate?.getMonth() + 1 : '0' + (day?.dayDate?.getMonth() + 1)
        for (let i = 0; i < this.attendedTrainings.length; i++) {
          if (this.attendedTrainings[i].split('T')[0] === `${day?.dayDate?.getFullYear()}-${monthNum}-${dayNum}`) {
            return true
          }
        }
        return false
      },
      checkIfTrainingIsMissed(day) {
        let dayNum = day?.dayDate?.getDate() > 9 ? day?.dayDate?.getDate() : '0' + day?.dayDate?.getDate()
        let monthNum = day?.dayDate?.getMonth() + 1 > 9 ? day?.dayDate?.getMonth() + 1 : '0' + (day?.dayDate?.getMonth() + 1)
        for (let i = 0; i < this.missedTrainings.length; i++) {
          if (this.missedTrainings[i].split('T')[0] === `${day?.dayDate?.getFullYear()}-${monthNum}-${dayNum}`) {
            return true
          }
        }
        return false
      },
      checkCurrentDay(day) {
        try {
          //set date day before day.dayDate
          const date = new Date(day.dayDate).setDate(new Date(day.dayDate).getDate() + 1)
          return new Date(date).toISOString().split('T')[0] === new Date().toISOString().split('T')[0]
        } catch (error) {
          return false
        }
      },
      emitPlanedTraining(day) {
        let planedTraining = this.planedTrainings.filter(training => new Date(training.date).toISOString().split('T')[0] === day?.dayDate?.toISOString().split('T')[0]) 
        const completedTraining = this.completedTrainings.filter(training => new Date(training.date).toISOString().split('T')[0] === day?.dayDate?.toISOString().split('T')[0])
        completedTraining.forEach(training => {
          planedTraining.push(training)
        })
        console.log('planedTraining -------- ', planedTraining)
        console.log('completedTraining -------- ', completedTraining)
        if (planedTraining.length === 0) return

        if (planedTraining.length > 1) {
          this.$emit('planedTraining', planedTraining)
          return
        } else if (planedTraining.length === 1) {
          this.$emit('planedTraining', planedTraining[0])
          return
        } else {
          return
        }
        
      }
    }
  }
</script>
